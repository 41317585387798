export const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  REBECCA_PURPLE: '#663399',
  BLUE: '#1a58cc',
};

export const NAV_ITEMS = [
  { to: '/what-we-do', label: 'Capabilities' },
  { to: '/work', label: 'Work' },
  { to: '/blog', label: 'Blog' },
  { to: '/contact', label: 'Contact' },
  // { href: '/docs/', label: 'Contact' },
];
